import React, { useEffect } from 'react';

function Loader() {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.body.classList.add('is-loaded');
    }, 2500);

    // Nettoyage de la classe lors du démontage du composant
    return () => {
      clearTimeout(timer);
      document.body.classList.remove('is-loaded');
    };
  }, []);

    return (
      <div className="loader">
        <div className="loader__content">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.37 128.17" width="131.3699951171875" height="128.1699981689453"><path d="M15.31,45.75c0-5.65,2.46-9.45,8.22-9.45s8.23,3.81,8.22,9.45v81.51H79.28c5.42,0,9.8,2.77,9.8,8s-4.39,7.95-9.8,7.95H24.51c-8,0-9.21-2.65-9.21-11.06Z" transform="translate(-15.3 -15)" fill="#fff" className="svg-elem-1"></path><path d="M121.58,35.6h-5.3c-5.88,0-8.65,2.3-10.61,8.64L89.42,90.11h-.23L72.71,44.24C70.75,37.9,68,35.6,62.1,35.6H56.57c-5.07,0-10.26,1.73-10.26,9.33v60.75c0,4.73,2.31,9.22,8.42,9.22s8.41-4.49,8.41-9.22V53.58h.23L81,108.67c1.26,3.92,4.15,5.53,8.07,5.53s6.8-1.61,8.06-5.53l17.64-55.09H115V78.69c0,4.72,2.3,9.22,8.41,9.22s8.42-4.5,8.42-9.22V44.93C131.84,37.33,126.65,35.6,121.58,35.6Z" transform="translate(-15.3 -15)" fill="#fff" className="svg-elem-2"></path><rect x="99.97" y="84.56" width="15.94" height="15" rx="7.36" fill="#368f8b" className="svg-elem-3"></rect><path d="M142.47,134.88H26.78a3.2,3.2,0,0,1-3.2-3.2V19.2a3.2,3.2,0,0,1,3.2-3.2H142.47a3.2,3.2,0,0,1,3.2,3.2V131.68A3.2,3.2,0,0,1,142.47,134.88Z" transform="translate(-15.3 -15)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" className="svg-elem-4"></path></svg>
        </div>
      </div>
    );
  }
  
  export default Loader;