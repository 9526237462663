import React, { useState, useEffect } from 'react';

function NavigationMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function closeMenu() {
    setIsMenuOpen(false);
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('menu-opened');
    } else {
      document.body.classList.remove('menu-opened');
    }
  }, [isMenuOpen]);

  return (
    <>
      <button className={`btn-nav small-hidden medium-hidden large-hidden ${isMenuOpen ? 'is-active' : ''}`} onClick={toggleMenu}>
        Menu
      </button>
      <nav id="navigation" className={`${isMenuOpen ? 'is-opened' : ''}`}>
        <ul className="unstyled man flex-row main-nav">
          <li className="main-nav--item" data-to-scrollspy-id="portfolio"><a href="#portfolio" onClick={closeMenu}>Portfolio</a></li>
          <li className="main-nav--item" data-to-scrollspy-id="about"><a href="#about"  onClick={closeMenu}>A propos</a></li>
          <li className="main-nav--item" data-to-scrollspy-id="resume"><a href="#resume"  onClick={closeMenu}>Mon CV</a></li>
          <li className="main-nav--item" data-to-scrollspy-id="contact"><a href="#contact"  onClick={closeMenu}>Me contacter</a></li>
        </ul>
      </nav>
    </>
  );
}

export default NavigationMenu;