import React from 'react';

const Blockquote = ({ icon, text }) => {
  return ( 
    <blockquote className="quote mtauto mbauto mrs mls">
    <span><i className={`${icon} fa-lg`}></i><br />{text}</span>
    </blockquote>
    );
  
};

export default Blockquote;