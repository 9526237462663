import React from "react";

class TimeDifference extends React.Component {

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
  }

  getTimeDifference = (from, to, yearsOnly = false) => {
    if (to === "today") {
      to = new Date();
    } else {
      to = new Date(to);
    }
      
    const difference = to - from;
    const one_year = 1000 * 60 * 60 * 24 * 365;
    const years = Math.floor(difference / one_year);
    const months = Math.floor((difference - years * one_year) / (1000 * 60 * 60 * 24 * 30));
  
    let yearLabel = "an";
    let monthLabel = "mois";
  
    if (years !== 1) {
      yearLabel = "ans";
    }
  
    if (months !== 1) {
      monthLabel = "mois";
    }
  
    let result = "";
  
    if (years !== 0) {
      result = years + " " + yearLabel;
    }
  
    if (!yearsOnly && months !== 0) {
      if (years !== 0) {
        result += " ";
      }
      result += months + " " + monthLabel;
    }
  
    if (result === "") {
      result = "0 mois";
    }
  
    return result;
  };

  render() {
    const { from, to, onlyYears } = this.props;
    const timeDifference = this.getTimeDifference(new Date(from), to, onlyYears);
    return <span>{timeDifference}</span>;
  }
}

export default TimeDifference;