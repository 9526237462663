import React from "react";
import Hero from './components/Sections/Hero';
import Portfolio from './components/Sections/Portfolio';
import About from './components/Sections/About';
import Skills from './components/Sections/Skills';
import Loader from './components/Loader';
import Resume from "./components/Sections/Resume";
import Contact from './components/Sections/Contact';
import ScrollSpy from "react-ui-scrollspy";

import './App.scss';

function App() {
  return (
    <>
    <ScrollSpy activeClass={"active"}>
    <Loader/>
      <Hero/>
      <section className="mtm mbl" id="portfolio">
        <Portfolio/>
      </section>
      
      <section className="mtl" id="about">
        <About/>
      </section>

      <section className="mtm">
        <Skills/>
      </section>

      <section className="bg-light" id="resume">
        <Resume/>
      </section>

      <section className="bg-dark" id="contact">
        <Contact/>
      </section>      
    </ScrollSpy>
    </>
  );
}
export default App;