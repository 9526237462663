import React from 'react';

const Image = ({ fileName, extension, className, altTag, portfolio }) => {
  const portfolioPath = "/crea";
  const imagePath = require(`../assets/images${portfolio ? portfolioPath : ""}/${fileName}.${extension}`);
  let imagePath2x;
  let srcSet = "";
  if (extension !== "svg") {
    imagePath2x = require(`../assets/images${portfolio ? portfolioPath : ""}/${fileName}@2x.${extension}`);
    srcSet = `${imagePath2x} 2x`;
  }
  return ( 
    <img
      className={className}
      src={imagePath}
      srcSet={srcSet}
      alt={altTag}
    />
    );
  
};

export default Image;