import ContactList from '../ContactList';
import Parallax from 'react-rellax';
import Image from '../Image';
import SectionTitle from '../SectionTitle';

function Contact() {
    return (
    <div className="row inner ptl pbl">
        <SectionTitle text="Echangeons" contact/>
        <div className="col-xs-12 col-md-6 col-md-offset-1">
            <Parallax speed={-1} percentage={0.35} >                
            <h2>Entrons en contact</h2>
            <p>Vous avez des questions ou souhaitez échanger n'hésitez pas à me contacter via les différentes plateformes ci-dessous ou en m'envoyant directement un message !<br />
            J'essayerai de vous répondre au plus vite.</p>
            <div className='mbs'>
             <ContactList />
            </div>
            <a className="btn btn--primary" href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#108;&#109;&#46;&#108;&#117;&#99;&#109;&#97;&#105;&#116;&#114;&#101;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;">M'envoyer un message</a>
            </Parallax>
        </div>
        <div className="tiny-hidden small-hidden col-xs-12 col-md-offset-1 col-md-2 txtright">
        <Parallax speed={-1} percentage={0.35} >
            <Image className="w80" fileName="lm-logo-white" extension="svg" altTag="logo"  />
        </Parallax>
        </div>
    </div>
    );
  }
  
  export default Contact;