import Blockquote from '../Blockquote';
import SectionTitle from '../SectionTitle';

function Skills() {
    return (      
        <div className="row inner">
            <SectionTitle text="Ce que je sais faire" />            
            <div className="col-xs-12 col-md-9 col-md-offset-1">
            <div className="row pbm">
                <div className="col-xs-12">
                        <h2>Mes compétences</h2>
                        <p>Actuellement je suis lead UI Designer au sein d’une Tribe et collabore avec de nombreuses équipes : des UX Designers, Product Owners et Développeurs.<br />
                        Embarqué sur les différents projets, j'identifie avec les UX les points d’améliorations.<br />
                        Je suis ensuite chargé de la création des maquettes, de la mise en place de la charte graphique tout en veillant à son respect.<br />
                        Enfin, je vais participer aux design review avec les développeurs avant les mises en prod.
                        </p>
                    </div>
                    <div className="col-xs-12 col-md-7">
                        <p className="medium-man large-man">Comme vous le savez désormais, je suis fan de pixels animés et donc de motion design. Ce qui est parfait car je suis également chargé de mettre en place le motion au sein de l’UI :<br/>
                        via des micros interactions dans l’interface mais aussi des animations sur des illustrations aux points clés des parcours, grâce à lottie pour le playback. <br/>
                        La prise de vue video, le montage et la création d’animations sont ma tasse de thé glacé. After effects et Premiere Pro sont certainement mes logiciels favoris de la suite CC.
                        </p>
                    </div>
                    <div className="col-xs-12 col-md-5 d-flex center-sm">
                        <Blockquote 
                            icon="fa-user-ninja-solid" 
                            text="Référent UI design et motion design" 
                        />
                    </div>
                    <div className="col-xs-12">
                        <p className="mts">Au niveau Développent Front, je maîtrise le HTML/CSS ainsi que le préprocesseur SASS. J’ai eu l’occasion de travailler sur le framework Symfony en l’utilisant en tant qu’intégrateur grâce à twig. Avec de solides notions de javascript, je m’autoforme récemment sur React. C’est autant un plaisir d’apprendre que d’être en capacité de comprendre et anticiper des problématiques pour le design.</p>

                        <p>Le monde du print ne m’est pas totalement inconnu. J'ai été amené à créer toute sorte de documents sur Indesign et Illustrator : de la grosse PLV en passant par du magazine jusqu’à la petite carte de visite.</p>
                    </div>
                    <div className="col-xs-12 col-md-4 col-lg-5 d-flex center-xs">
                        <Blockquote 
                            icon="fa-handshake-regular" 
                            text="J’apprécie le travail en équipe" 
                        />
                    </div>
                    <div className="col-xs-12 col-md-8 col-lg-7">
                        <p className="mts medium-man large-man">Enfin, je suis à l’écoute lors des problématiques et demandes ce qui me permet d’atteindre au plus juste le résultat voulu, en y apportant mon expertise. Le tout avec rigueur et rapidité. J’apprécie le travail en équipe : apprendre aux autres mais surtout apprendre des autres.</p>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Skills;