
import React from "react";

const currentYear = new Date().getFullYear();

class Footer extends React.Component {
  render() {
    return(
      <footer>
        <div className="inner">© {currentYear} Luc Maître - UI Designer / Développeur Front-End</div>
      </footer>
    );
  }
}
export default Footer;