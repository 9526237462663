import SectionTitle from '../SectionTitle';
import PortfolioItem from '../PorfolioItem';


function Portfolio() {
    const portfolioEntries = [
        {
            title: "Site et apps leboncoin",
            tag: "Animations",
            id: "lbc",
            content: [
              {
                className: "shadow",
                fileName: "lbc_shadow",
                extension: "png",
                speed: -1,
                percentage: 0.5,
              },
              {
                className: "stars",
                fileName: "lbc_stars",
                extension: "png",
                speed: 1,
                percentage: 0.5,
              },
            ],
            lottie: [
              {
                className: "anim",
                fileName: "lbcAnim",
                speed: -1,
                percentage: 0.5,
              },
            ],
          },
        {
          title: "Agriaffaires MachineryZone Truckscorner",
          tag: "Sites",
          link: "https://www.behance.net/gallery/83444011/Reponsive-Design-Chef-de-projet-technique",
          id: "rwd",
          content: [
            {
                className: "brands",
                fileName: "rwd_brands",
                extension: "png",
            },
            {
                className: "browser",
                fileName: "rwd_browser",
                extension: "png",
            },
            {
                className: "diorama",
                fileName: "rwd_diorama",
                extension: "png",
                speed: -1,
                percentage: 0.5,
            },
          ]
        },
        {
            title: "Recommandations Techniques",
            tag: "Site",
            link: "https://www.behance.net/gallery/96803739/Site-support-publicit",
            id: "reco",
            content: [
                {
                    className: "cogwheels",
                    fileName: "reco_cogwheels",
                    extension: "png",
                    speed: 0.5,
                    percentage: 0.5,
                },
                {
                    className: "browser",
                    fileName: "reco_browser",
                    extension: "png",
                },
                {
                    className: "devices",
                    fileName: "reco_devices",
                    extension: "png",
                    speed: -0.5,
                    percentage: 0.5,
                },
            ]
        },
        {
            title: "Régie publicitaire",
            tag: "Site",
            link: "https://www.behance.net/gallery/96707751/Site-AMT-Advertising",
            id: "regie",
            content: [
                {
                    className: "macbook",
                    fileName: "regie_macbook",
                    extension: "png",
                    speed: 0.5,
                    percentage: 0.5,
                },
                {
                    className: "megaphone",
                    fileName: "regie_megaphone",
                    extension: "png",
                    speed: -0.5,
                    percentage: 0.5,
                },
            ]
        },
        {
            title: "Lancement Truckscorner",
            tag: "Vidéo",
            link: "https://www.behance.net/gallery/83492675/Vido-lancement-site-Truckscorner",
            id: "tc",
            content: [
                {
                    className: "truck",
                    fileName: "tc_truck",
                    extension: "png",
                    speed: 0.5,
                    percentage: 0.5,
                },
                {
                    className: "speed",
                    fileName: "tc_speed",
                    extension: "png",
                    speed: -0.5,
                    percentage: 0.5,
                },
                {
                    className: "icons",
                    fileName: "tc_icons",
                    extension: "png",
                    speed: -0.5,
                    percentage: 0.5,
                },
                {
                    className: "smoke",
                    fileName: "tc_smoke",
                    extension: "png",
                    speed: 0.8,
                    percentage: 0.5,
                },
            ]
        },
        {
            title: "Depussay",
            tag: "Magazines",
            link: "https://www.behance.net/gallery/83440943/Maquettage-magazine-Depussay",
            id: "depussay",
            content: [
                {
                    className: "machine",
                    fileName: "depussay_machine",
                    extension: "png",
                    speed: 0.5,
                    percentage: 0.5,
                },
                {
                    className: "mag",
                    fileName: "depussay_mag",
                    extension: "png",
                    speed: -0.5,
                    percentage: 0.5,
                },
                {
                    className: "colors",
                    fileName: "depussay_colors",
                    extension: "png",
                },
            ]
        },
        {
            title: "Salon SIMA",
            tag: "Video",
            link: "https://www.behance.net/gallery/83493217/Motiondesign-stand-Agriaffaires-a-Innovagri-2018",
            id: "sima",
            content: [
                {
                    className: "calendar",
                    fileName: "sima_calendar",
                    extension: "png",
                    speed: 0.5,
                    percentage: 0.5,
                },
                {
                    className: "geoloc",
                    fileName: "sima_geoloc",
                    extension: "png",
                    speed:1,
                    percentage: 0.5,
                },
            ]
        },
      ];

    return (
    <div className="row inner">
        <SectionTitle text="Une selection" />   
        <div className="col-xs-12 col-md-9 col-md-offset-1">
        <h2>Mon travail</h2>
        <div className="row mtl creative-container">
            <PortfolioItem portfolioEntries={portfolioEntries} />
        </div>
        <div className="txtcenter">
            <a href="https://www.behance.net/lucmaitre" target="_blank" rel="noreferrer" className="btn btn--primary">Voir plus</a>
        </div>
        </div>
    </div>

    );
  }
  
  export default Portfolio;