import React, { useState, useEffect } from 'react';
import Image from './Image';
import Parallax from 'react-rellax';
import ResponsiveCondition from './ResponsiveCondition';
import Lottie from "lottie-react";

const PortfolioItem = ({ portfolioEntries }) => {
  const isMobile = ResponsiveCondition();
  const mobileSpeed = 0;

  const [lottieAnimations, setLottieAnimations] = useState({});

  const importLottieAnimation = async (fileName) => {
    const lottieAnimation = await import(`../assets/lottie/${fileName}.json`);
    return lottieAnimation.default;
  };

  useEffect(() => {
    const loadLottieAnimations = async () => {
      const animations = {};
      for (const portfolioEntry of portfolioEntries) {
        if (portfolioEntry.lottie) {
          for (const item of portfolioEntry.lottie) {
            const lottieAnimation = await importLottieAnimation(item.fileName);
            animations[item.fileName] = lottieAnimation;
          }
        }
      }
      setLottieAnimations(animations);
    };

    loadLottieAnimations();
  }, [portfolioEntries]);

  return (
    <>
      {portfolioEntries.map((portfolioEntry, index) => (
        <div className="col-xs-12 col-md-6" key={index}>
          <Parallax
            speed={isMobile ? mobileSpeed : index % 2 === 1 ? 0.5 : -0.5}
            percentage={0.5}
          >
            {portfolioEntry.link ? (
              <a
                href={portfolioEntry.link}
                target="_blank"
                rel="noreferrer"
                className={`creative creative--${portfolioEntry.id}`}
              >
                <span className="creative__title">
                  <span className="u-bold u-big">{portfolioEntry.title}</span>
                  <span className="tag--inverse tag--small">{portfolioEntry.tag}</span>
                  <span className="mts btn-outline">Découvrir</span>
                </span>

                {portfolioEntry.content.map((contentImage, index) => (
                  <div className={`creative__${contentImage.className}`} key={index}>
                    {contentImage.speed && contentImage.percentage ? (
                      <Parallax
                        speed={contentImage.speed}
                        percentage={contentImage.percentage}
                      >
                        <Image
                          fileName={contentImage.fileName}
                          extension={contentImage.extension}
                          altTag=""
                          portfolio
                        />
                      </Parallax>
                    ) : (
                      <Image
                        fileName={contentImage.fileName}
                        extension={contentImage.extension}
                        altTag=""
                        portfolio
                      />
                    )}
                  </div>
                ))}

                {portfolioEntry.lottie && (
                  <>
                    {portfolioEntry.lottie.map((item, index) => {
                      const lottieAnimation = lottieAnimations[item.fileName];
                      if (lottieAnimation) {
                        return (
                          <div key={index} className={`creative__${item.className}`}>
                            {item.speed && item.percentage ? (
                              <Parallax speed={item.speed} percentage={item.percentage}>
                                <Lottie className="lottie" animationData={lottieAnimation} />
                              </Parallax>
                            ) : (
                              <Lottie className="lottie" animationData={lottieAnimation} />
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </>
                )}
                
                <div className="creative__corner"></div>
              </a>
            ) : (
              <div className={`creative creative--${portfolioEntry.id}`}>
                <span className="creative__title">
                  <span className="u-bold u-big">{portfolioEntry.title}</span>
                  <span className="tag--inverse tag--small">{portfolioEntry.tag}</span>
                </span>

                {portfolioEntry.content.map((contentImage, index) => (
                  <div className={`creative__${contentImage.className}`} key={index}>
                    {contentImage.speed && contentImage.percentage ? (
                      <Parallax
                        speed={contentImage.speed}
                        percentage={contentImage.percentage}
                      >
                        <Image
                          fileName={contentImage.fileName}
                          extension={contentImage.extension}
                          altTag=""
                          portfolio
                        />
                      </Parallax>
                    ) : (
                      <Image
                        fileName={contentImage.fileName}
                        extension={contentImage.extension}
                        altTag=""
                        portfolio
                      />
                    )}
                  </div>
                ))}

                {portfolioEntry.lottie && (
                  <>
                    {portfolioEntry.lottie.map((item, index) => {
                      const lottieAnimation = lottieAnimations[item.fileName];
                      if (lottieAnimation) {
                        return (
                          <div key={index} className={`creative__${item.className}`}>
                            {item.speed && item.percentage ? (
                              <Parallax speed={item.speed} percentage={item.percentage}>
                                <Lottie className="lottie" animationData={lottieAnimation} />
                              </Parallax>
                            ) : (
                              <Lottie className="lottie" animationData={lottieAnimation} />
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </>
                )}

                <div className="creative__corner"></div>
              </div>
            )}
          </Parallax>
        </div>
      ))}
    </>
  );
};

export default PortfolioItem;