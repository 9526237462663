import TimeDifference from "../TimeDifference";
import Parallax from 'react-rellax';
import SectionTitle from '../SectionTitle';
import ContactList from '../ContactList';

function Hero() {
    return (
        <div className="hero hero--full triangle triangle--white triangle-down">
            <div className="row mauto inner">
            <SectionTitle text="Pour commencer" header />
            <div className="col-xs-12 col-md-9 col-md-offset-1">
                <Parallax className="hero__initiate rellax" speed={3}>
                    <h1>UI Designer / Intégrateur Front-End</h1>
                    <p className="u-big">Je cumule aujourd’hui plus de  <TimeDifference from="2009-09-01" to="today" onlyYears /> d’expérience dans les métiers du web et de la communication. Naturellement curieux, je suis en permanence en recherche des nouvelles tendances et techniques.
            Toujours prêt à partager et faire évoluer mes connaissances et compétences !</p>
                    <p className="mtm u-big color--muted">Luc Maitre</p>
                    <ContactList/>
                </Parallax>
            </div>
            </div>
            <a className="hero__next" href="#portfolio">
            <i className="fa-2x fa-chevron-down-solid"></i>
            </a>
        </div>
    );
  }
  
  export default Hero;