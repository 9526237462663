import React from "react";
import logo from '../../../assets/images/lm-logo-white.svg';
import Headroom from "react-headroom";
import Menu from "../../Menu"


class Header extends React.Component {
  render() {
    return(
      <header>
        <Headroom disableInlineStyles>      
          <div className="inner">
              <div className="logo left">
                  <a href="#root">
                    <img width="50px" src={logo} title="logo Luc Maitre" alt="LM"/>
                    </a>
              </div>
              <div className="right">
              <Menu/>
              </div>
          </div>
        </Headroom>
      </header>

  
    );
  }
}
export default Header;