import ResumeItem from "../ResumeItem";
import TimeDifference from "../TimeDifference";
import Parallax from 'react-rellax';
import Image from '../Image';

function Resume() {
  const cvPath = require(`../../assets/docs/cv-lmaitre-web.pdf`);
  const resumeEntries = [
    {
      from: "2022-01-03",
      to: "today",
      tags: "Lead UI Designer",
      company: "leboncoin",
      content: (
        <>
          <p className="mts mbn">En tant que lead UI mes missions sont les suivantes :</p>
            <ul>
              <li>Collaborer avec les équipes de ma Tribe (UX, Product Owners, Développeurs)</li>
              <li>Cerner les enjeux des projets</li>
              <li>Identifier et analyser les points d’amélioration avec les UX</li>
              <li>Produire les maquettes graphiques + déclinaisons web/iOS/android (Figma)</li>
              <li>Faire les design review avec les développeurs avant mise en prod</li>
              <li>Définir de la charte graphique</li>
              <li>Aider à la création du Design System</li>
            </ul>
          <p className="mbn">J'ai également comme mission la mise place et le développement du motion au sein de l'UI :</p>
            <ul>
              <li>Création de guidelines pour les micros-interactions et transitions</li>
              <li>Définition et production d'animations sur des illustrations pour leur intégration aux points clés sur les parcours (Lottie / Rive)</li>
            </ul>
        </>
    )
    },
    {
      from: "2011-11-01",
      to: "2022-01-03",
      tags: "UI Designer, Dev. Front-End",
      company: "MB Diffusion",
      content: (
        <>
          <p className="mts mbn">Chef de projet technique design et intégration HTML/CSS sur différents projets. Notamment sur la refonte responsive des sites des marques Agriaffaires, MachineryZone et Truckscorner.</p>
            <ul>
              <li>Méthode agile</li>
              <li>Définition de la charte graphique</li>
              <li>Production de wireframes et maquettes (adobe XD)</li>
              <li>Définition et production de documentation concernant les guidelines d'intégration HTML/CSS</li>
              <li>Intégration HTML/CSS des pages (KNACSS / SASS / JAVASCRIPT / Symfony)</li>
            </ul>
          <p className="mbn">Mes missions récurrentes :</p>
            <ul>
              <li>Création de bannières publicitaires et landing page (autopromotion des marques et client) (adobe animate / HTML-CSS-JS / Unbouce)</li>
              <li>Création et envoi d'emailing via la plateforme Smartfocus (smartfocus)</li>
              <li>Création de contenu print : flyers / affiches / PLV / magazines (adobe indesign / adobe illustrator)</li>
              <li>Production de vidéo : prise de vue live / montage / motion design (adobe premiere / adobe after effects)</li>
            </ul>
        </>
    )
    },

    {
      from: "2011-01-01",
      to: "2013-01-01",
      tags: "Auto entrepreneur",
      company: null,
      content: (
        <>
          <p className="mts mbn">Ce statut m'a permis réaliser quelques projets à côté : création de sites et motion design.</p>
        </>
      )
    },

    {
      from: "2011-02-01",
      to: "2011-07-31",
      tags: "Animateur",
      company: "Ligue de l'enseignement de l'Aube",
      content: (
        <>
          <p className="mts">Service civique au sein du service audiovisuel de la Ligue de l'Enseignement de l'Aube.</p>
          <p>Participation à l’organisation du festival de court métrage Troyes Première Marche.
          Création de séquences pour un vidéo mapping.<br />
          Encadrement de jeunes pour de l’initiation à la vidéo.</p>
        </>
      )
    },

    {

      from: "2009-09-01",
      to: "2010-09-01",
      tags: "Webdesigner",
      company: "JMBruneau",
      content: (
        <>
          <p className="mts">J’ai occupé le poste de webdesigner chez JMBruneau le temps d’une année d’alternance. J’étais ici chargé de l’administration du contenu d’un site lancé quelques semaines avant mon arrivé : Kalligo.com. Ma mission concernait également la préparation de newsletters hebdomadaires et des visuels de promotion.</p>
        </>
      )
    },

    {

      from: "2009-04-01",
      to: "2009-06-30",
      tags: "Infographiste",
      company: "Corporation des Entrepreneurs Généraux du Québec",
      content: (
        <>
          <p className="mts">J'avais décidé pour mon stage de fin d'études de le faire au Canada.
  En plus d'être un voyage plein de découvertes, j'ai été amené à gérer le contenu du site de l'entreprise et faire divers réalisations print pour les évènements organisés par celle-ci.</p>
        </>
      )
    },
  ];

    return (
      <>
      <div className="row inner mbm">
        <div className="col-xs-1 col-md-2">
            <div className="gap gap--border"></div>
        </div>
        <div className="col-xs-10 col-md-9 col-xs-offset-1">
            <h2 className="mtm">Mon expérience</h2>
            <p>Je cumule aujourd’hui plus de <TimeDifference from="2009-09-01" to="today" onlyYears /> d’expérience dans les métiers du web et de la communication.</p>
        </div>
        {resumeEntries.map((resumeEntry, index) => (
          <ResumeItem 
          key = {index}
          from = {resumeEntry.from}
          to = {resumeEntry.to}
          tags = {resumeEntry.tags}
          company= {resumeEntry.company}
          content= {resumeEntry.content}
          />
        ))}
      </div>

      <figure className="promote promote--tall triangle triangle--dark">
        <div className="row center-xs inner promote-content">
            <div className="col-xs-8">
              <Parallax percentage={0.35} speed={1}>
                <p>Pour imprimer ou regarder plus tard vous pouvez télécharger mon CV en PDF</p>
                <a href={cvPath} rel="noreferrer" target="_blank" className="btn btn--primary">Télécharger mon cv</a>
              </Parallax>
            </div>
        </div>
        <div className="promote__img">
          <Parallax speed={-2} percentage={0.5} >
                <Image fileName="resume-download-bg" extension="jpg" altTag="cv"  />
          </Parallax>
        </div>
      </figure>
      </>
    );
  }
  
  export default Resume;