import Parallax from 'react-rellax';
import Image from '../Image';
import Blockquote from '../Blockquote';
import SectionTitle from '../SectionTitle';


function About() {
    
    return (
      <>
      <div className="row inner">
        <SectionTitle text="En savoir un peu plus sur moi" />
          <div className="col-xs-12 col-md-9 col-md-offset-1">
            <div className="row">
                <div className="col-xs-12 col-md-6">
                      <h2>Qui suis-je ?</h2>
                      <p>C’est seulement quelques années après l’année 1989 que je découvre le jeu vidéo. Du gros pixel monochrome de Mario sur GameBoy à ceux plus colorés de Monkey Island je me trouve une véritable fascination du pixel animé !<br />
                      Aujourd’hui encore les jeux vidéo occupent toujours mes temps libres. Plus qu’un loisir, ils sont aussi pour moi une source d’inspiration.
                      </p>
                  </div>
                  <div className="col-xs-12 col-md-6 d-flex center-sm"> 
                        <Blockquote 
                            icon="fa-gamepad-solid" 
                            text="Tout à commencé avec une histoire de pixels" 
                        />
                  </div>
                  <div className="col-xs-12">
                      <p className="mts medium-man large-man">Les films et séries sont également une passion. Gros consommateur de ce média je suis tout autant créateur de contenu. Je prends beaucoup de plaisir à filmer et monter des images. Fervent de voyages c’est un plaisir de pouvoir combiner ces passions.<br />
                      Il m’arrive de participer également à des courts métrages, que ce soit en apportant une aide lors du tournage ou pour la création d’effets visuels. J’ai participé de façon récurrente et bénévole au Festival du Film Court de Troyes. Il est possible d’ailleurs de retrouver mes vidéos sur <a href="https://www.youtube.com/channel/UCeBGjDBLK8DYX30PrrROVCQ" target="_blank" rel="noreferrer">ma chaîne youtube</a>.</p>

                      <p>Ce sont ces passions qui m’ont amenées à faire les formations qui m’ont conduites aujourd’hui aux métiers du web. C’est à l’IUT de Troyes que j’ai obtenu un DUT Services et Réseaux de Communication ainsi qu’une licence pro en alternance Ingénierie de l’Internet et du Multimédia.</p>

                      <p>Un peu sportif, mais pas trop, j’ai une forte appétence dans la voile. J’ai eu la chance d’avoir pu faire de nombreux stages de catamaran en bretagne. Je suis d’ailleurs en possession d’un beau pin’s voile orange de la FFV. J’ai même eu l’occasion d’essayer la planche à voile au Maroc  il y a quelques années.<br />
                      Je suis également adepte de peinture en forêt. C'est toujours pour moi un grand plaisir de participer à une partie de paintball.
                      </p>
                  </div>
              </div>
          </div>
      </div>

      <figure className="promote mtm">
        <div className="promote__img">
            <Parallax speed={-1} percentage={0.5} >
            <Image fileName="bohinj" extension="jpg" altTag="lac de Bohinj Slovenie"  />
            </Parallax>
        </div>
          <figcaption>lac de Bohinj - Slovenie</figcaption>
          <div className="row inner">
              <div className="col-xs-8 col-md-5">
                  <Parallax speed={2} percentage={0.5} >
                    <Blockquote 
                        icon="fa-camera-retro-solid" 
                        text="Passionné de vidéo et de voyages, j'apprécie particulièrement filmer mes escapades" 
                    />
                  </Parallax>
              </div>
          </div>
      </figure>
      </>

    );
  }
  
  export default About;