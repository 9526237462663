import React from 'react';
import Parallax from 'react-rellax';
import ResponsiveCondition from './ResponsiveCondition';

const SectionTitle = ({text, header, contact }) => {
  const isMobile = ResponsiveCondition();
  let mobileSpeed, percentage;

  if (contact) {
    mobileSpeed = 0;
    percentage = 0;
  } else if (header) {
    mobileSpeed = 3;
    percentage = null;
  }
  else {
    mobileSpeed = 0;
    percentage = 0;
  }

  return ( 
          <div className="col-xs-12 col-md-2">
              <div className="gap gap--border gap--rwd">
                  <Parallax speed={isMobile ? mobileSpeed : -2} percentage={percentage}>
                    {text}
                  </Parallax>
              </div>
          </div>
    );
  
};

export default SectionTitle;