import React from 'react';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import TimeDifference from "./TimeDifference";

const ResumeItem = ({ from, to, tags, company, content }) => {

  let currentClass, formattedDateTo, formattedDateFrom;

  if (to === "today") {
    currentClass = "resume-step--current";
    formattedDateTo = "Aujourd'hui";

  } else {
    currentClass = null;
    formattedDateTo = format(new Date(to), 'MMMM yyyy', { locale: fr });
  }
  
  formattedDateFrom = format(new Date(from), 'MMMM yyyy', { locale: fr });

  const tagList = tags.split(',');
  

  return (
    <>
      <div className="col-xs-1 col-md-2">
        <div className={`gap gap--border resume-step ${currentClass}`}></div>
      </div>
      <div className="col-xs-10 col-md-9 col-xs-offset-1 pbm">
          <div className="row bottom-xs mbs">
              <div className="col-xs-12 col-md u-big resume-date">{formattedDateFrom} - {formattedDateTo}</div>
              <div className="color--primary u-bold pls u-txt-ellipsis">
              <TimeDifference from={from} to={to} />
              </div>
          </div>
          {tagList.map(tag => (
            <span key={tag} className="tag--inverse mrs">{tag.trim()}</span>
          ))}
          <strong>{company}</strong>
          {content}
                  
        </div>
    </>

    );
  
};

export default ResumeItem;