import { useState, useEffect } from 'react';

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
}

function ResponsiveCondition() {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;

  return isMobile;
}

export default ResponsiveCondition;